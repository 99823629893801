import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form-model", _vm._b({
    ref: "dynamicValidateForm",
    attrs: {
      labelCol: {
        style: "width: 85px"
      },
      model: _vm.dynamicValidateForm
    }
  }, "a-form-model", _vm.formItemLayoutWithOutLabel, false), [_vm._l(_vm.dynamicValidateForm.lists, function (item, index) {
    return _c("a-form-model-item", _vm._b({
      key: item.key,
      attrs: {
        label: index === 0 ? "列表" : "",
        prop: "lists." + index + ".name",
        rules: [{
          required: true,
          message: "不能为空",
          trigger: "blur"
        }, {
          max: 100,
          message: "最大长度不能超过100",
          trigger: "blur"
        }]
      }
    }, "a-form-model-item", index === 0 ? _vm.formItemLayout : {}, false), [_c("a-input", {
      staticStyle: {
        width: "60%",
        "margin-right": "8px"
      },
      attrs: {
        placeholder: "请输入"
      },
      model: {
        value: item.name,
        callback: function callback($$v) {
          _vm.$set(item, "name", $$v);
        },
        expression: "item.name"
      }
    }), _vm.dynamicValidateForm.lists.length > 1 ? _c("a-icon", {
      staticClass: "dynamic-delete-button",
      attrs: {
        type: "minus-circle-o",
        disabled: _vm.dynamicValidateForm.lists.length === 1
      },
      on: {
        click: function click($event) {
          return _vm.removeItem(item);
        }
      }
    }) : _vm._e()], 1);
  }), _c("a-form-model-item", _vm._b({}, "a-form-model-item", _vm.formItemLayoutWithOutLabel, false), [_c("a-button", {
    staticStyle: {
      width: "60%"
    },
    attrs: {
      type: "dashed"
    },
    on: {
      click: _vm.addItem
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v(" 新增 ")], 1)], 1), _c("a-form-model-item", _vm._b({}, "a-form-model-item", _vm.formItemLayoutWithOutLabel, false), [_c("a-button", {
    attrs: {
      type: "primary",
      "html-type": "submit"
    },
    on: {
      click: function click($event) {
        return _vm.submitForm("dynamicValidateForm");
      }
    }
  }, [_vm._v(" 提交 ")]), _c("a-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    on: {
      click: function click($event) {
        return _vm.resetForm("dynamicValidateForm");
      }
    }
  }, [_vm._v(" 重置 ")])], 1)], 2)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };