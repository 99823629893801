import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import { CourseManageApi } from "@/api";
export default {
  name: "dcCurriculumForm",
  components: {},
  props: {
    editId: [String, Number],
    categoryTitle: String
  },
  data: function data() {
    return {
      isEdit: "",
      disabledLoading: false,
      item: {},
      gradeidOption: [],
      categoryidOptions: [],
      collectionsList: [],
      curriculumInfo: {},
      form: {
        categoryids: [],
        gradeid: undefined,
        collectionid: undefined,
        sort: ""
      }
    };
  },
  computed: {
    isDisabled: function isDisabled() {
      return this.categoryTitle == "编辑分类";
    },
    imgProportion: function imgProportion() {
      return [536, 538];
    }
  },
  created: function created() {
    this.getStudycollectionsList();
    this.getCategoryList();
    this.getGradeList();
    if (this.editId) {
      this.getInfo();
    }
  },
  methods: {
    getInfo: function getInfo() {
      var _this = this;
      CourseManageApi.studycurriculumInfo({
        id: this.editId
      }).then(function (res) {
        _this.curriculumInfo = res;
        _this.form.sort = _this.curriculumInfo.sort;
        _this.form.gradeid = _this.curriculumInfo.gradeid;
        _this.form.collectionid = _this.curriculumInfo.collectionid;
        var items = [];
        _this.curriculumInfo.categorys.map(function (item) {
          items.push(item.id);
        });
        _this.form.categoryids = items;
        // categoryids: [],
      });
    },
    categorySelectChange: function categorySelectChange(value) {
      this.form.categoryids = value;
    },
    getCategoryList: function getCategoryList() {
      var _this2 = this;
      CourseManageApi.categoryList({
        page: 1,
        size: 99
      }).then(function (res) {
        _this2.categoryidOptions = res;
      });
    },
    getGradeList: function getGradeList() {
      var _this3 = this;
      CourseManageApi.gradeList({
        page: 1,
        size: 99
      }).then(function (res) {
        _this3.gradeidOption = res;
      });
    },
    getStudycollectionsList: function getStudycollectionsList() {
      var _this4 = this;
      CourseManageApi.studycollectionsList({
        page: 1,
        size: 99
      }).then(function (res) {
        _this4.collectionsList = res.list;
      });
    },
    cancle: function cancle() {
      this.$emit("handleAddSubmit");
    },
    submit: function submit() {
      var _this5 = this;
      if (this.disabledLoading) return;
      this.$refs.dcCategoryForm.validate(function (valid) {
        if (valid) {
          _this5.disabledLoading = true;
          var params = _objectSpread({}, _this5.form);
          var method = "studycurriculumAdd";
          if (_this5.editId) {
            method = "studycurriculumUpdate";
            params.id = _this5.editId;
          }
          CourseManageApi[method](_objectSpread({}, params)).then(function () {
            _this5.toast("操作成功", "success");
            _this5.$emit("handleAddSubmit");
          }).catch(function () {
            _this5.disabledLoading = false;
          });
        } else {
          return false;
        }
      });
    },
    revalidateField: function revalidateField(field) {
      var _this6 = this;
      setTimeout(function () {
        _this6.$refs.dcCategoryForm.validateField(field);
      }, 100);
    }
  }
};