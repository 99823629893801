import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.sort.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form-model", {
    ref: "dcCategoryForm",
    attrs: {
      labelCol: {
        style: "width: 85px"
      },
      model: _vm.form
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "课程合集",
      prop: "collectionid",
      rules: {
        required: true,
        message: "请选择课程合集"
      }
    }
  }, [_c("a-select", {
    attrs: {
      placeholder: "请选择课程合集"
    },
    model: {
      value: _vm.form.collectionid,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "collectionid", $$v);
      },
      expression: "form.collectionid"
    }
  }, _vm._l(_vm.collectionsList, function (item, key) {
    return _c("a-select-option", {
      key: key,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")]);
  }), 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "级别",
      prop: "gradeid",
      rules: {
        required: true,
        message: "请选择级别"
      }
    }
  }, [_c("a-select", {
    attrs: {
      placeholder: "请选择级别"
    },
    model: {
      value: _vm.form.gradeid,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "gradeid", $$v);
      },
      expression: "form.gradeid"
    }
  }, _vm._l(_vm.gradeidOption, function (item, key) {
    return _c("a-select-option", {
      key: key,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "标签",
      prop: "categoryids",
      rules: {
        required: true,
        message: "请选择标签"
      }
    }
  }, [_c("a-select", {
    attrs: {
      mode: "multiple",
      "default-value": _vm.form.categoryids,
      placeholder: "请选择标签"
    },
    on: {
      change: _vm.categorySelectChange
    },
    model: {
      value: _vm.form.categoryids,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "categoryids", $$v);
      },
      expression: "form.categoryids"
    }
  }, _vm._l(_vm.categoryidOptions, function (item, key) {
    return _c("a-select-option", {
      key: key,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "合集排序",
      prop: "sort",
      rules: {
        required: true,
        message: "请输入排序"
      }
    }
  }, [_c("a-input", {
    attrs: {
      autoComplete: "off",
      placeholder: "请输入排序",
      disabled: _vm.isDisabled,
      maxlength: "10"
    },
    on: {
      change: function change(e) {
        e.target.value = e.target.value.trim();
        return e;
      }
    },
    model: {
      value: _vm.form.sort,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "sort", $$v);
      },
      expression: "form.sort"
    }
  })], 1)], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("a-button", {
    staticClass: "btn btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.disabledLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.editId ? "保存" : "创建"))])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };